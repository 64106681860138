import React from 'react'
import { NavLink } from 'react-router-dom'
export const NavItemsList = () => {
    return (
        <div className="navbar__navItemsList navbar__listVertical animate__animated animate__slideInDown">
             <div className="navbar__navItem2">
                <NavLink className="navbar__nav-link navbar__navtext" to="/inicio">Inicio</NavLink>
            </div>

            <hr className="navbar__lineSepare"/>

            <div className="navbar__navItem2">
                <NavLink className="navbar__nav-link navbar__navtext" to="/conocenos">Quienes somos</NavLink>
            </div>

            <hr className="navbar__lineSepare"/>

            <div className="navbar__navItem2">
                <button className="navbar__navtext navbar__navAulaVirtual">Aula Virtual</button>
                {/* <a class="nav-link navtext NavAulaVirtual" href="#">Aula Virtual</a> */}
            </div>
            
        </div>
    )
}
