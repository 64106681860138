import React, { useEffect } from 'react';
import { Carrusel } from '../helpComponents/Carrusel';
import Aos from 'aos';
import 'aos/dist/aos.css'

export const Cartel1 = () => {

    useEffect(() => {
        Aos.init({duration: 2000})
    }, [])

    return (
        <div className="flex flex-row home__card1-main hover-increase-slow">
            <div data-aos="fade-right" className="flex flex-column flex-center home__card1-text">
                <div className="home__card1-gruper-horizontal flex flex-row">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ED4242" className="bi bi-record-circle-fill mr-1 mt-1 home__card1 animate__animated animate__bounceIn" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-8 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                    </svg>
                    <h3>Clases en vivo</h3>
                </div>
                <p className="home__card1-text-text">Recibe clases directamente a travez de una de las mejores plataformas existentes y accede a varios recursos en nuestra aula virtual</p>
            </div>
            <div className="flex flex-center home__card1-img-container">
                <Carrusel />
            </div>
        </div>
    )
}
