const baseUrl = process.env.REACT_APP_API_URL;

export const fetchSinToken = ( endpoint, data, method = 'GET' ) => {

    const url = `${ baseUrl }/${ endpoint }`;

    if ( method === 'GET' ) {
        return fetch( url );
    } 
    else if(method === 'POST' && (endpoint === 'add' || endpoint === 'addProffesor' || endpoint === 'addUnit')){
        return fetch( url, {
            method,
            headers: {
            },
            body: data
        });
    }
    else {
        return fetch( url, {
            method,
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify( data )
        });
    }
}

export const fetchFrontReq = ( endpoint, data, method = 'GET' ) => {

    const url = `${ baseUrl }/${ endpoint }`;
    
    if ( method === 'GET' ) {
        return fetch( url );
    } 
    else{
        return fetch( url, {
            method,
            headers: {
            },
            body: data
        });
    }
}
