import React from 'react'

import img1 from './../images/carr1.jpg';
import img2 from './../images/carr2.jpg';
import img3 from './../images/carr3.jpg';
import img4 from './../images/carr4.jpg';
import img5 from './../images/carr5.jpg';


export const Carrusel = () => {
    return (
        <div id="carouselExampleSlidesOnly" className="carousel slide home__card1-img hover-increase" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active" data-bs-interval="4000">
                    <img src={img1} className="d-block w-100" alt="img1" />
                </div>
                <div className="carousel-item" data-bs-interval="4000">
                    <img src={img2} className="d-block w-100" alt="img2" />
                </div>
                <div className="carousel-item" data-bs-interval="4000">
                    <img src={img3} className="d-block w-100" alt="img3" />
                </div>
                <div className="carousel-item" data-bs-interval="4000">
                    <img src={img4} className="d-block w-100" alt="img4" />
                </div>
                <div className="carousel-item" data-bs-interval="4000">
                    <img src={img5} className="d-block w-100" alt="img5" />
                </div>
            </div>

        </div>
    )
}
