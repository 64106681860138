import React from 'react'
import { CursosCard } from './CursosCard'

import moment from 'moment';
import 'moment/locale/es';
import { useSelector } from 'react-redux';
import { string2Date } from '../../helpers/strDate';
moment.locale('es');


export const CursosAnteriores = () => {
    const { curses } = useSelector(state => state.internalCurses);

    return (
        <div className="flex flex-column mt-5">
            <div className="title2">Cursos Anteriores</div>
            <hr className="hr"/>
            <div className="flex flex-column flex-center max-container-width">
            {curses.map(curse=>(
                (moment().isAfter(string2Date(curse.startCurse), 'day'))&&
                <CursosCard key={curse.id} curse={curse} />
            ))}

            </div>
        </div>
    )
}
