import React, { useEffect } from 'react'
import { Images } from '../helpComponents/Images'
import { Star } from '../helpComponents/Star';

import Aos from 'aos';
import 'aos/dist/aos.css';

import moment from 'moment';
import 'moment/locale/es';
import { Link } from 'react-router-dom';
import { string2Date } from '../../helpers/strDate';
moment.locale('es');
const baseUrlImg = process.env.REACT_APP_API_URL_IMAGES+"/";

export const CursosCard = ({ curse }) => {

    useEffect(() => {
        Aos.init({duration: 1000})
    }, []);
    
    return (
        <Link data-aos="fade" className="cursos__card flex flex-row max-container-width pointer" to={`/curso/${curse.id}`}>
            <div className="cursos__card-image-container flex flex-center">
                <Images imageURL={baseUrlImg+curse.imgURL} />
            </div>
            <div className="cursos__card-text-container">
                <div className="cursos__card-title">{curse.title}</div>
                <div className="cursos__card-subtitle">
                    {curse.subtitle}
                </div>
                <div className="cursos__card-text-small cursos__card-profesor">Impartido por:
                    {curse.proffesors.map(profesor => (
                        <span key={profesor.idProfesor}> {profesor.name}, </span>
                    ))}
                </div>
                <div className="cursos__card-text-small cursos__card-dificultad">
                    Dificultad:
                    {
                        <Star number={curse.difficult} />
                    }
                </div>
                <div className="cursos__card-text-small cursos__card-inicio">Inicio: {moment(string2Date(curse.startCurse), 'mm/dd/yyyy').format('LL')}</div>
                <div className="cursos__card-text-small cursos__card-fin">Fin: {moment(string2Date(curse.endCurse), 'mm/dd/yyyy').format('LL')}</div>
            </div>
            <div className="cursos__card-price-container">
                {curse.promotion && moment().isBetween(curse.promoStart, curse.promoEnd)?
                    <>
                        <div className="cursos__card-price">{curse.pricePromotion.toFixed(2)} US$</div>
                        <div className="cursos__card-promo">{curse.price.toFixed(2)} US$</div>
                    </>
                    :
                    <>
                        <div className="cursos__card-price">{curse.price.toFixed(2)} US$</div>
                    </>
                }
            </div>
        </Link>
    )
}
