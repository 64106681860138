import React from 'react'
import { useSelector } from 'react-redux';
import { CursosAdminCard } from './CursosAdminCard'

export const CursosAdminContainer = () => {

    const {curses} = useSelector(state => state.internalCurses);
    // console.log(curses);
    return (
        <div className="flex flex-column max-container admin__cursos-container">
            <div className="title2">Editar Cursos</div>

            <hr className="hr"/>
            <div className="flex flex-column flex-center max-container-width">
                {
                curses.map((curse)=>(
                    <CursosAdminCard curse={curse} key={curse.id} />
                ))}
            </div>
        </div>
    )
}
