import React from 'react'

export const CursoCuerpo = ({ curse }) => {

    const { units, duration, horario, evaluacion, certificacion, difficult } = curse
    return (
        <div className="curso__cuerpo-main max-container-width flex flex-row">
            <div className="curso__cuerpo-col1 flex flex-column">
                {units.map((unit, index) => (
                    <div key={index} className="curso__unidad-container flex flex-column">
                        <div className="curso__unidad-titulo">
                            {unit.unitTitle}
                        </div>
                        <div className="curso__unidad-description">
                            {unit.unitContent}
                        </div>
                    </div>
                ))}
            </div>
            <div className="curso__cuerpo-col2 flex">
                <div className="curso__cuerpo-cajas-container flex flex-column">
                    <div className="curso__detalles-cajita flex flex-row">
                        <div className="curso__detalles-titulo">Duración:</div>
                        <div className="curso__detalles-content">{duration}</div>
                    </div>
                    <div className="curso__detalles-cajita flex flex-row">
                        <div className="curso__detalles-titulo">Horario:</div>
                        <div className="curso__detalles-content">{horario}</div>
                    </div>
                    <div className="curso__detalles-cajita flex flex-row">
                        <div className="curso__detalles-titulo">Evaluación:</div>
                        <div className="curso__detalles-content">{evaluacion}</div>
                    </div>
                    <div className="curso__detalles-cajita flex flex-row">
                        <div className="curso__detalles-titulo">Certificación:</div>
                        <div className="curso__detalles-content">{certificacion}</div>
                    </div>
                    <div className="curso__detalles-cajita flex flex-row">
                        <div className="curso__detalles-titulo">Nivel:</div>
                        <div className="curso__detalles-content">
                            {difficult <= 2 ?
                                <>Fácil</>
                                : difficult == 5 ?
                                    <>Difícil</>
                                    :
                                    <>Intermedio</>

                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
