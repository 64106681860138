import React from 'react'

export const ProfessorForm = ({ setDeleProffesor, proffesor, indexProf, formValues, setFormValues }) => {

    const { proffesors } = formValues;

    //el target debe ser el nombre de la variable dentro del objeto y el id
    const handleInputProfessorsChange = ({ target }) => {
        // console.log(target.name);
        const [nameVarProff, idVarProff] = target.name.split(' ');
        // console.log(nameVarProff);
        // console.log(idVarProff);
        const proffIndex = proffesors.findIndex(element => element.idProfesor == parseInt(idVarProff))
        // console.log(proffIndex);

        const newProffesorsArray = [...formValues.proffesors];

        newProffesorsArray[proffIndex] = {
            ...newProffesorsArray[proffIndex],
            [nameVarProff]: target.value
        };

        setFormValues({
            ...formValues,
            proffesors: newProffesorsArray
        })
    }

    return (
        <div className="form-control admin__map-container mt-1" key={indexProf}>
            <div className="flex flex-center admin__container-close">
                <label>Nombre del profesor:</label>
                <span
                    name={indexProf}
                    onClick={() => (setDeleProffesor(indexProf))}
                // onClick={(this) => (console.log(this.name))}
                >
                    <i className="far fa-times-circle admin__close-small"></i>
                </span>
            </div>
            <input
                type="text"
                className={`form-control admin__input-map`}
                placeholder="Nombre del profesor"
                name={"name " + proffesor.idProfesor}
                autoComplete="off"
                value={proffesors[indexProf].name}
                onChange={handleInputProfessorsChange}
            />
            <label>País:</label>
            <input
                type="text"
                className={`form-control admin__input-map`}
                placeholder="País de orígen"
                name={"country " + proffesor.idProfesor}
                autoComplete="off"
                value={proffesors[indexProf].country}
                onChange={handleInputProfessorsChange}
            />
            <label>Descripción del profesor:</label>
            <textarea
                type="text"
                className="form-control admin__textarea-map"
                placeholder="Información general del curso"
                rows="5"
                name={"description " + proffesor.idProfesor}
                value={proffesors[indexProf].description}
                onChange={handleInputProfessorsChange}
            ></textarea>
        </div>
    )
}
