import React from 'react'
import { useDispatch } from 'react-redux'
import { uiOpenModal } from '../../actions/ui'

export const AddButton = () => {

    const dispatch = useDispatch();

    const handleButtonClick = ()=>{
        dispatch(uiOpenModal());
    }
    return (
        <div
            className="max-container-width flex flex-center mt-5 hover-increase"
            onClick={handleButtonClick}
        >
            <button className="admin__button-plus">
                <span><i className="fas fa-plus"></i></span> Añadir nuevo curso
            </button>
        </div>
    )
}
