import React from 'react';
import { Link } from 'react-router-dom';
import Typewriter from 'typewriter-effect'
import caratula from '../images/caratula.png'

export const Carrusel = () => {

    return (
        <div className="home__carrusel" style={{backgroundImage:`url(${caratula})`}}>
            
            <div className="home__carrusel-gradient2 hover-increase-slow"> 
                <div className="home__carrusel-text flex flex-column flex-center">
                    <div className="animate__animated animate__backInDown">
                        <p className="home__carrusel-title">Fórmate online con los mejores profesionales</p>
                    </div>
                    <div className="home__carrusel-dinamic-text">
                        <Typewriter
                            options={{
                                autoStart: true,
                                loop: true,
                            }}

                            onInit={(typewriter) => {
                                typewriter
                                .typeString('Cientos de alumnos han confiado en nosotros')
                                .pauseFor(2500)
                                .deleteAll()
                                .typeString('Obtén certificados de todos nuestros cursos')
                                .deleteAll()
                                .start();
                            }}
                        />
                    </div>
                    <div>
                        <Link className="butn butn-secundary flex  home__carrusel-button hover-increase" to="/cursos">Explorar cursos</Link>
                    </div>

                </div>
               
            </div>
        </div>
    )
}


