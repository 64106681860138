import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

export const date2String = (dateValue) => {
    return (moment(dateValue).format('YYYY-MM-DD')).toString();
}

export const string2Date = (StringValue) => {
    try {
        return new Date(StringValue.replace(/-/g, '\/'));
    } catch (error) {
        return new Date(StringValue);
    }

}
