import { initialCurseEmpty } from "../dataFree/initialCurses";
import { types } from "../types/types";


import moment from 'moment';
import 'moment/locale/es';
import { date2String, string2Date } from "../helpers/strDate";
moment.locale('es');


const initialState = initialCurseEmpty;


const compareAsc = (a, b) => {
    if ((moment(string2Date(a.startCurse))).isBefore(moment(string2Date(b.startCurse)))) {
        return -1;
    }
    if (moment(string2Date(a.startCurse)).isAfter(moment(string2Date(b.startCurse)))) {
        return 1;
    }
    return 0;
}

const compareDesc = (a, b) => {
    if ((moment(string2Date(b.startCurse))).isBefore(moment(string2Date(a.startCurse)))) {
        return -1;
    }
    if (moment(string2Date(b.startCurse)).isAfter(moment(string2Date(a.startCurse)))) {
        return 1;
    }
    return 0;
}


export const internalCursesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.getCurses:
            return{
                ...state,
                curses: [...action.payload],
                checkIn: false
            }
        case types.curseSetActive:
            return {
                ...state,
                activeCurse: action.payload
            };
        case types.curseAddNew:
            return {
                ...state,
                curses: [
                    ...state.curses,
                    action.payload
                ]
            }
        case types.curseClearActivecurse:
            return {
                ...state,
                activeCurse: null
            }

        case types.curseUpdated:
            return {
                ...state,
                curses: state.curses.map(
                    e => (e.id === action.payload.id) ? action.payload : e
                )
            }
        case types.curseDeleted:
            return {
                ...state,
                curses: state.curses.filter(e => (e.id !== state.activeCurse.id) && e),
                activeCurse: null
            }

        case types.curseSortAsc:
            return {
                ...state,
                ...state.curses.sort(compareAsc)
            }
        case types.curseSortDesc:
            return {
                ...state,
                ...state.curses.sort(compareDesc)
            }
        default:
            return state;
    }
}