import React, { useEffect, useState } from 'react'
import { CursosContent } from '../cursos/CursosContent'
import { CursosSideBar } from '../cursos/CursosSideBar'

import Aos from 'aos';
import 'aos/dist/aos.css'

export const CursosScreen = () => {
    
    useEffect(() => {
        Aos.init({duration: 1300});
        window.scrollTo(0, 0)
    }, [])

    const [filterShow, setFiterShow] = useState('All'); // All, Active, Old
    
    return (
        <div data-aos="fade" className="screen__new-screen flex flex-row">
            <CursosSideBar filterShow={filterShow} setFiterShow={setFiterShow}/>
            <CursosContent filterShow={filterShow}/>
        </div>
    )
}
