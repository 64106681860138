import React from 'react'

export const UnitForm = ({setDeleUnit, unit, indexUnit, formValues, setFormValues}) => {

    const {units} = formValues;

    // console.log(units);
    //el target debe ser el nombre de la variable dentro del objeto y el id
    const handleInputUnitsChange = ({ target }) => {
        // console.log(target.name);
        const [nameVarUnit, idVarUnit] = target.name.split(' ');
        // console.log(nameVarUnit);
        // console.log(idVarUnit);
        const UnitIndex = units.findIndex(element => element.idUnit == parseInt(idVarUnit))
        // console.log(UnitIndex);

        const newUnitsArray = [...formValues.units];

        newUnitsArray[UnitIndex] = {
            ...newUnitsArray[UnitIndex],
            [nameVarUnit]: target.value
        };

        setFormValues({
            ...formValues,
            units: newUnitsArray
        })
    }

    return (
        <div className="form-control admin__map-container mt-1" key={indexUnit}>
            <div className="flex flex-center admin__container-close">
                <label>Título:</label>
                <span
                    name={indexUnit}
                    onClick={()=>(setDeleUnit(indexUnit))}
                    // onClick={(this) => (console.log(this.name))}
                >
                    <i className="far fa-times-circle admin__close-small"></i>
                </span>
            </div>
            <input
                type="text"
                className={`form-control admin__input-map`}
                placeholder="Título de la unidad"
                name={"unitTitle " + unit.idUnit}
                autoComplete="off"
                value={units[indexUnit].unitTitle}
                onChange={handleInputUnitsChange}
            />
            <label>Descripción de la unidad:</label>
            <textarea
                type="text"
                className="form-control admin__textarea-map"
                placeholder="Descripción de la unidad"
                rows="5"
                name={"unitContent " + unit.idUnit}
                value={units[indexUnit].unitContent}
                onChange={handleInputUnitsChange}
            ></textarea>
        </div>
    )
}
