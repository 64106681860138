import React from 'react'
import { NavLink } from 'react-router-dom'

export const NavCourSearcList = () => {
    return (
        <div className="navbar__navCourSearcList navbar__listVertical animate__animated animate__slideInDown">
            <div className="navbar__navItem2">
                <NavLink className="navbar__nav-link navbar__navtext" to="/cursos">
                    Cursos
                    </NavLink>
            </div>

            <hr className="navbar__lineSepare"/>

            <div className="navbar__navItem2">
            <input
                className="navbar__navSearch"
                placeholder="Buscar"
            />
            </div>
        </div>
    )
}
