import React from 'react'

import moment from 'moment';
import 'moment/locale/es';
import { Link } from 'react-router-dom';
import { string2Date } from '../../helpers/strDate';
moment.locale('es');
const baseUrlImg = process.env.REACT_APP_API_URL_IMAGES+"/";

export const CursoCabecera = ({ curse }) => {
    
    const { title, subtitle, price, startCurse, endCurse, promotion, pricePromotion, promoStart, promoEnd, imgURL, disponible} = curse;

    return (
        <div className="curso__cabecera max-containter-width flex flex-row">
            <div className="curso__cabecera_col1 flex flex-column">
                <div className="curso__title">{title}</div>
                <div className="curso__subtitle">{subtitle}</div>

                {(disponible && moment().isBefore(startCurse))?
                <div className="curso__cupos_disponibles">¡Aún hay cupos disponibles!</div>
                :
                <div className="curso__cupos_disponibles">Ya no hay cupos disponible</div>
                }
                <div className="curso_box1 flex flex-column flex-center">
                    <div className="curso__fecha-inicio">Inicia el {moment(string2Date(startCurse), 'mm/dd/yyyy').format('LL')}</div>
                    <div className="curso__fecha-fin">Termina el {moment(string2Date(endCurse), 'mm/dd/yyyy').format('LL')}</div>
                </div>
            </div>
            <div className="curso__cabecera_col2 flex flex-column flex-center">
                <div className="curso__image-cont">
                    <img src={baseUrlImg + imgURL} className="img-thumbnail curso__main-img-tumbnail" alt="..." />
                    {/* <Images imageURL={baseUrlImg+imgURL} /> */}
                </div>
                {promotion && moment().isBetween(string2Date(promoStart), string2Date(promoEnd)) ?
                    <>
                        <div className="curse__price">{pricePromotion.toFixed(2)} US$</div>
                        <div className="curse__price-promo">{price.toFixed(2)} US$</div>
                    </>
                    :
                    <>
                        <div className="curse__price">{price.toFixed(2)} US$</div>
                    </>
                }

                {(moment().isSameOrBefore(string2Date(startCurse),'day') && disponible)?
                <Link className="butn butn-secundary curse__pagar-btn" to={`/pagar/${curse.id}`}>Inscribete ahora</Link>
                :
                <div className="curse__pagar-btn-none " >No disponible</div>
                }
            </div>
        </div>
    )
}

