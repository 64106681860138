import React from 'react'
import { FooterV1 } from './FooterV1'
import { FooterV2 } from './FooterV2'

export const Footer = () => {
    return (
        <div className="footer__main">
            <FooterV1 />
            <FooterV2 />
        </div>
    )
}
