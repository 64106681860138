import React from 'react'

export const FooterV2 = () => {
    return (
        <div className="footer__v2">
            <div className="footer__copyRight">
                <p>Soluciones Académicas RV - Todos los derechos reservados</p>
            </div>
            <div className="footer__author">
                <p className="footer__AutorRef">Contactar al <a target="_blank" rel="noopener noreferrer" href="https://github.com/jonathancuento/" className=" footer__autorRef">Desarrollador</a></p>
            </div>
        </div>
    )
}
