import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { curseSortAsc, curseSortDesc } from '../../actions/cursesInternal';

export const CursosSideBar = ({filterShow, setFiterShow}) => {

    const [dateState, setdateState] = useState({asc: false, desc: false})
    const dispatch = useDispatch()
    const handleShowAll = (e)=>{
        e.preventDefault();
        setFiterShow('All')
    }
    const handleShowActive = (e)=>{
        e.preventDefault();
        setFiterShow('Active')
    }    
    const handleShowOld = (e)=>{
        e.preventDefault();
        setFiterShow('Old')
    }

    const handleSSortAsc = (e)=>{
        e.preventDefault();
        setdateState({asc: true, desc: false})
        dispatch(curseSortAsc());
    }

    const handleSSortDesc = (e)=>{
        e.preventDefault();
        setdateState({asc: false, desc: true})
        dispatch(curseSortDesc());
    }
    return (
        <div className="cursos__sidebar-main flex flex-column">
            <h5 >Filtros</h5>
            <hr className="hr mb-3"/>
            <div className={`cursos__text-sidebar ${filterShow==='All' && 'cursos__text-active'}`} onClick={handleShowAll}>Mostrar todos los cursos</div>
            <div className={`cursos__text-sidebar ${filterShow==='Active' && 'cursos__text-active'}`}  onClick={handleShowActive}>Mostrar solo cursos disponibles</div>
            <div className={`cursos__text-sidebar ${filterShow==='Old' && 'cursos__text-active'}`}  onClick={handleShowOld}>Mostrar solo cursos anteriores</div>
            <h5 className="mt-5">Filtrar por fecha</h5>
            <hr className="hr mb-3"/>
            <div className={`cursos__text-sidebar ${dateState.asc && 'cursos__text-active'}`} onClick={handleSSortAsc}>Mostrar mas recientes primero</div>
            <div className={`cursos__text-sidebar ${dateState.desc && 'cursos__text-active'}`} onClick={handleSSortDesc}>Mostrar anteriores primero</div>
        </div>
    )
}
