import React from 'react'

export const Images = ({imageURL}) => {

    const img = new Image();
    img.src = imageURL;
    return (
        <>
            <img 
                className={`${img.width>img.height? "img-bigger-width" : "img-bigger-height"}`}
                src={imageURL} 
                alt="hola"
            />
        </>
    )
}
