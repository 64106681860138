import React from 'react'

const baseUrlImg = process.env.REACT_APP_API_URL_IMAGES + "/";


export const CursoProfessors = ({ curse }) => {
    const { proffesors } = curse;
    return (
        <>
            <h5 className="curso__professor-tit max-container-width">Profesores:</h5>
            <div className="max-container-width flex flex-row curso__proffesors-main">
                {proffesors.map(proffesor => (
                    <div className="curso__professor-card" key={proffesor.idProfesor}>
                        <div className="card-body flex flex-center flex-column">
                            <div className="curso_profesor_img-container flex flex-center">
                                <img src={baseUrlImg + proffesor.imgProf} className="img-thumbnail curso__professor-img-tumbnail" alt="..." />
                            </div>
                            <h5 className="card-title mt-3">{proffesor.name}</h5>
                            <p className="card-title mt-2">{proffesor.country}</p>
                            <p className="card-text mt-2">{proffesor.description}</p>
                        </div>
                    </div>
                ))}

            </div>
        </>
    )
}
