import React from 'react'
import { useDispatch } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'
import { logout } from '../../actions/auth'

export const NavbarAdmin = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const handleLogOut = ()=>{
        history.replace('/login');
        dispatch(logout())
    }
    return (
        <div className="navbar navbar-dark bg-dark nb-4 admin__navbarAdmin">
            <span className="navbar-brand">
                Panel de administración
            </span>
            <NavLink className="navbar__nav-link navbar__navtext mr-1" active="navbar__navtext-active" to="/inicio">Ir a inicio</NavLink>

            <button 
                className="btn btn-outline-danger"
                onClick={handleLogOut}
            >
                <span>Salir </span>
                <i className="fas fa-sign-out-alt" />
            </button>
        </div>
    )
}
