import React from 'react'
import logo from '../images/logo-small.png'
import { NavLink } from 'react-router-dom'

export const NavbarLogo = () => {
    return (
        <div className="navbar__navbarLogo">
            <NavLink className="navbar__navbarLogo" active="navbar__navtext-active" to="/inicio">
                <img className="navbar__logo-image" src={logo} alt="logo"></img>
            </NavLink>
        </div>
    )
}
