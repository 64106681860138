import React from 'react'
import { DestacadosCard } from './DestacadosCard'
import { useSelector } from 'react-redux';

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');



const compareDesc = (a, b) => {
    if ((moment(b.startCurse)).isBefore(moment(a.startCurse))) {
        return -1;
    }
    if (moment(b.startCurse).isAfter(moment(a.startCurse))) {
        return 1;
    }
    return 0;
}

export const Destacados = () => {


    const { curses } = useSelector(state => state.internalCurses);
    const cursesDestacados = [...curses];
console.log(cursesDestacados);
    cursesDestacados.sort(compareDesc);

    return (
        <div className="flex flex-column home__desatacados-main">
            <h2 className="title1">Cursos destacados</h2>
            <hr className="hr" />
            <div className="flex flex-row home__desatacados-cards-container">
                {cursesDestacados.map((curse, index) => (
                    index <= 2 &&
                    <DestacadosCard key={index} curse={curse} />
                ))}
            </div>
        </div>
    )
}
