import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import { startEventLoading } from "../actions/cursesInternal";
import { AdminScreen } from "../components/Screens/AdminScreen";

import { LoginScreen } from '../components/Screens/LoginScreen'
import { DashboardRouter } from "./DashboardRouter";
import { useDispatch } from 'react-redux';
import { LoadingScreen } from "../components/Screens/LoadingScreen";

export const AppRouter = () => {

    const dispatch = useDispatch();

    const {checkIn} = useSelector(state => state.internalCurses)

    useEffect(() => {
        dispatch(startEventLoading());
    }, [dispatch])


    const { logged } = useSelector(state => state.auth);

    if ( checkIn ) {
        return <LoadingScreen />
    }

    return (
        <>
            <Router>
                <div className="screen__main">
                    <Switch>
                        {
                            logged ?
                                <Route exact path="/admin" component={AdminScreen} />
                                :
                                <Route exact path="/login" component={LoginScreen} />
                        }
                        <Route exact path="/login" component={LoginScreen} />
                        <Route path="/" component={DashboardRouter} />

                    </Switch>
                </div>
            </Router>
        </>
    )
}
