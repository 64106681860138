import Swal from "sweetalert2";
import { fetchFrontReq, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";

export const startEventLoading = () => {
    return async (dispatch) => {

        try {

            const resp = await fetchSinToken('getcurses');
            const body = await resp.json();

            dispatch(getCurses(body));

        } catch (error) {
            console.log(error)
        }

    }
}

export const startEventDeleting = (id) => {
    return async (dispatch) => {

        try {
            await fetchSinToken(`deletecurses/${id}`, [], 'DELETE');
            // const body = await resp.json();
            dispatch(curseDeleted());

        } catch (error) {
            console.log(error)
        }

    }
}

export const startEventAdding = (curseAll, curseObject, units, proffesors) => {
    return async (dispatch) => {
        try {
            //añado curso
            const respCurso = await fetchSinToken('add', curseObject, 'POST')
            const bodyCurse = await respCurso.json();

            //añado unidades
            for (let i = 0; i < units.length; i++) {
                let tempForm = new FormData();
                tempForm.append('idCurso', bodyCurse.id);
                tempForm.append('unitTitle', units[i].unitTitle);
                tempForm.append('unitContent', units[i].unitContent);

                await fetchSinToken('addUnit', tempForm, 'POST')
                // const bodyUnidades = await respUnidades.json();
            }

            //añado profesores
            for (let i = 0; i < proffesors.length; i++) {
                let tempForm = new FormData();
                tempForm.append('idCurso', bodyCurse.id);
                tempForm.append('name', proffesors[i].name);
                tempForm.append('country', proffesors[i].country);
                tempForm.append('description', proffesors[i].description);
                tempForm.append('imgProf', proffesors[i].imgProf);
                await fetchSinToken('addProffesor', tempForm, 'POST');
                // const bodyProffesor = await respProfesor.json();
            }
            dispatch(curseAddNew({
                ...curseAll,
                id: bodyCurse.id,
                imgURL: bodyCurse.curseResponse.imgURL,
                difficult: bodyCurse.curseResponse.difficult
            }));

            if (bodyCurse) {
                Swal.fire('El curso ha sido añadido correctamente');
            }
            else {
                Swal.fire(bodyCurse.msg);
            }

        } catch (error) {
            Swal.fire("Hubo un error importante con la base de datos.");
        }
    }
}



export const startEventUpdating = (curseAll, id, curseObject, units, proffesors) => {
    return async (dispatch) => {

        try {
            //actualizo curso (borro anterior y añado el nuevo)
            const respCurso = await fetchFrontReq(`updatecurse/${id}`, curseObject, 'POST');
            const bodyCurse = await respCurso.json();

            //añado unidades
            for (let i = 0; i < units.length; i++) {
                let tempForm = new FormData();
                tempForm.append('idCurso', bodyCurse.id);
                tempForm.append('unitTitle', units[i].unitTitle);
                tempForm.append('unitContent', units[i].unitContent);

                await fetchSinToken('addUnit', tempForm, 'POST')
                // const bodyUnidades = await respUnidades.json(); //creo que hay que borrar
            }

            //añado profesores
            for (let i = 0; i < proffesors.length; i++) {
                let tempForm = new FormData();
                tempForm.append('idCurso', bodyCurse.id);
                tempForm.append('name', proffesors[i].name);
                tempForm.append('country', proffesors[i].country);
                tempForm.append('description', proffesors[i].description);
                tempForm.append('imgProf', proffesors[i].imgProf);
                await fetchSinToken('addProffesor', tempForm, 'POST')
                // const bodyProffesor = await respProfesor.json(); //creo que hay que borrar
            }
            dispatch(curseUpdated({
                ...curseAll,
                id: bodyCurse.id,
                imgURL: bodyCurse.curseResponse.imgURL,
                difficult: bodyCurse.curseResponse.difficult
            }));

            if (bodyCurse) {
                Swal.fire('El curso se actualizó correctamente');
            }
            else {
                Swal.fire(bodyCurse.msg);
            }
        } catch (error) {
            Swal.fire("Hubo un error importante con la base de datos.");
        }
    }
}


const getCurses = (cursesList) => ({
    type: types.getCurses,
    payload: cursesList
})

export const curseAddNew = (curse) => ({
    type: types.curseAddNew,
    payload: curse
});

export const curseSetActive = (curse) => ({
    type: types.curseSetActive,
    payload: curse
});

export const curseClearActivecurse = () => ({
    type: types.curseClearActivecurse
})

export const curseUpdated = (curse) => ({
    type: types.curseUpdated,
    payload: curse
});

export const curseDeleted = () => ({
    type: types.curseDeleted
});

export const curseSortAsc = () => ({
    type: types.curseSortAsc
});

export const curseSortDesc = () => ({
    type: types.curseSortDesc
});