import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux';


import { uiCloseModal } from '../../actions/ui';
import { curseClearActivecurse, startEventAdding, startEventUpdating } from "./../../actions/cursesInternal";
import { initialCurseEmptyOnly } from '../../dataFree/initialCurses';
import { ProfessorForm } from './formElements/ProfessorForm';
import { UnitForm } from './formElements/UnitForm';
import { date2String, string2Date } from '../../helpers/strDate';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};


Modal.setAppElement('#root');

const initEmptyCurse = initialCurseEmptyOnly;

const initProffState = {
    idProfesor: null,
    name: '',
    country: '',
    description: '',
    imgProf: ''
};


const initUnitState = {
    idUnit: -1,
    unitTitle: '',
    unitContent: ''
};

export const CursosModal = () => {

    const dispatch = useDispatch()
    const { modalOpen } = useSelector(state => state.ui)
    const { activeCurse } = useSelector(state => state.internalCurses)

    const [titleValid, setTitleValid] = useState(true)
    const [descriptionValid, setDescriptionValid] = useState(true)
    const [priceValid, setPriceValid] = useState(true)
    const [durationValid, setDurationValid] = useState(true)
    const [horarioValid, setHorarioValid] = useState(true)
    const [evaluacionValid, setEvaluacionValid] = useState(true)
    const [certificacionValid, setCertificacionValid] = useState(true)


    const [imageProfesorValid, setImageProfesorValid] = useState(true);
    const [profNameValid, setProfNameValid] = useState(true);
    const [countryNameValid, setCountryNameValid] = useState(true);
    const [descriptionNameValid, setDescriptionNameValid] = useState(true);

    const [unitTitleValid, setUnitTitleValid] = useState(true);
    const [unitContentValid, setUnitContentValid] = useState(true);

    const [courseImageValid, setCurseImageValid] = useState(true);

    const [formValues, setFormValues] = useState(initEmptyCurse);

    const { disponible, duration, horario, evaluacion, certificacion, title, subtitle, price, promotion, pricePromotion, difficult, startCurse, endCurse, promoStart, promoEnd, imageURL, proffesors, units } = formValues;

    const [addProffesor, setAddProffesor] = useState(initProffState);
    const { name, country, description, imgProf } = addProffesor;
    const [deleProffesor, setDeleProffesor] = useState(-1)

    const [addUnit, setAddUnit] = useState(initUnitState);
    const { unitTitle, unitContent } = addUnit;
    const [deleUnit, setDeleUnit] = useState(-1);



    const handleCourseImage = (e) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.files[0]
            })
    }

    const handleProfImageChange = (e) => {
        setAddProffesor({
            ...addProffesor,
            [e.target.name]: e.target.files[0]
        })
    }
    ///////////////////////////////////////////// INICIA UNIDADES
    ////////////////////////////////////////////////////////////////


    useEffect(() => {
        try {
            const newUnitsArray = [...formValues.units] || null;
            if (newUnitsArray != null) {

                const newDeletedUnits = newUnitsArray.filter((unit, index) => index != parseInt(deleUnit));
                setFormValues({
                    ...formValues,
                    units: newDeletedUnits
                })
            }
        } catch (e) {
            console.log('Error')
        }
        setDeleUnit(-1)

    }, [deleUnit])



    const handleInputChangeUnit = ({ target }) => {
        setAddUnit({
            ...addUnit,
            [target.name]: target.value
        })
    }



    const handleAddUnitButton = (e) => {
        e.preventDefault();

        if (unitTitle.trim().length < 2) {
            return setUnitTitleValid(false);
        }
        else {
            setUnitTitleValid(true);
        }

        if (unitContent.trim().length < 2) {
            return setUnitContentValid(false);
        }
        else {
            setUnitContentValid(true);
        }
        setFormValues({
            ...formValues,
            units: [
                ...formValues.units,
                addUnit
            ]
        })
        setAddUnit(initUnitState);
    }

    ///////////////////////////////////////////// INICIA PROFESORES
    ////////////////////////////////////////////////////////////////


    useEffect(() => {
        try {
            const newProffesorsArray = [...formValues.proffesors] || null;
            if (newProffesorsArray != null) {

                const newDeletedProffesros = newProffesorsArray.filter((proffesor, index) => index != parseInt(deleProffesor));
                setFormValues({
                    ...formValues,
                    proffesors: newDeletedProffesros
                })
            }
        } catch (e) {
            console.log('Error')
        }
        setDeleProffesor(-1)

    }, [deleProffesor])


    const handleInputChangeProffesor = ({ target }) => {
        setAddProffesor({
            ...addProffesor,
            [target.name]: target.value
        })
    }

    const handleAddProffesorButton = (e) => {
        e.preventDefault();

        if (imgProf === '') {
            return setImageProfesorValid(false);
        }
        else {
            setImageProfesorValid(true);
        }

        if (name.trim().length < 2) {
            return setProfNameValid(false);
        }

        else {
            setProfNameValid(true);
        }

        if (country.trim().length < 2) {
            return setCountryNameValid(false);
        }
        else {
            setCountryNameValid(true);
        }

        if (description.trim().length < 2) {
            return setDescriptionNameValid(false);
        }
        else {
            setDescriptionNameValid(true);
        }

        setFormValues({
            ...formValues,
            proffesors: [
                ...formValues.proffesors,
                addProffesor
            ]
        })
        setAddProffesor(initProffState);
    }

    useEffect(() => {
        if (activeCurse) {
            setFormValues({
                ...activeCurse,
                startCurse: string2Date(activeCurse.startCurse),
                endCurse: string2Date(activeCurse.endCurse),
                promoStart: string2Date(activeCurse.promoStart),
                promoEnd: string2Date(activeCurse.promoEnd)

            });

        }
    }, [activeCurse, setFormValues])

    ///////////////////////////////////////////// INICIA HANLDE INPUTCHANGE
    ////////////////////////////////////////////////////////////////




    const handleInputChange = ({ target }) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value
        })
    }


    ///////////////////////////////////////////// INICIA PROMOCIONES
    ////////////////////////////////////////////////////////////////

    const handleCheckPromoChange = () => {
        setFormValues({
            ...formValues,
            promotion: !promotion
        })
    }


    const handleDisponibleChange = () => {
        setFormValues({
            ...formValues,
            disponible: !disponible
        })
    }


    const handleStartDateChangePromo = (e) => {
        setFormValues({
            ...formValues,
            promoStart: e
        });//para modificar mi objeto
    }

    const handleEndDateChangePromo = (e) => {
        setFormValues({
            ...formValues,
            promoEnd: e
        });
    }


    ///////////////////////////////////////////// INICIA CERRAR MODAL
    ////////////////////////////////////////////////////////////////
    const closeModal = () => {
        dispatch(uiCloseModal());
        dispatch(curseClearActivecurse());
        setFormValues(initEmptyCurse);
    }



    ///////////////////////////////////////////// INICIAN FECHAS
    ////////////////////////////////////////////////////////////////
    const handleStartDateChange = (e) => {
        setFormValues({
            ...formValues,
            startCurse: e
        });//para modificar mi objeto
    }

    const handleEndDateChange = (e) => {
        setFormValues({
            ...formValues,
            endCurse: e
        });
    }


    ///////////////////////////////////////////// INICIA EL HANDLE SUBMIT
    ////////////////////////////////////////////////////////////////

    const handleSubmitForm = (e) => {
        e.preventDefault();

        const momentStart = moment(startCurse);
        const momentEnd = moment(endCurse);


        if (momentStart.isSameOrAfter(momentEnd)) {
            Swal.fire('Error', 'La fecha de fin debe ser mayor a la de inicio', 'error')
        }
        if (formValues.imgURL === '' && !activeCurse) {
            return setCurseImageValid(false);
        }
        else {
            setCurseImageValid(true);
        }
        if (title.trim().length < 2) {
            return setTitleValid(false);
        }

        else {
            setTitleValid(true);
        }
        if (subtitle.trim().length < 2) {
            return setDescriptionValid(false);
        }
        else {
            setDescriptionValid(true);
        }
        if (price === 0) {
            return setPriceValid(false);
        }
        else {
            setPriceValid(true);
        }
        if (duration.trim().length < 2) {
            return setDurationValid(false);
        }
        else {
            setDurationValid(true);
        }
        if (horario.trim().length < 2) {
            return setHorarioValid(false);
        }
        else {
            setHorarioValid(true);
        }
        if (evaluacion.trim().length < 2) {
            return setEvaluacionValid(false);
        }
        else {
            setEvaluacionValid(true);
        }
        if (certificacion.trim().length < 2) {
            return setCertificacionValid(false);
        }
        else {
            setCertificacionValid(true);
        }


        let formCurseAdd = new FormData();

        formCurseAdd.append('disponible', disponible ? 1 : 0);
        formCurseAdd.append('duration', formValues.duration);
        formCurseAdd.append('horario', formValues.horario);
        formCurseAdd.append('evaluacion', formValues.evaluacion);
        formCurseAdd.append('certificacion', formValues.certificacion);
        formCurseAdd.append('title', formValues.title);
        formCurseAdd.append('subtitle', formValues.subtitle);
        formCurseAdd.append('price', formValues.price);
        formCurseAdd.append('promotion', promotion ? 1 : 0);
        formCurseAdd.append('pricePromotion', formValues.pricePromotion);
        formCurseAdd.append('difficult', formValues.difficult);
        formCurseAdd.append('startCurse', date2String(formValues.startCurse));
        formCurseAdd.append('endCurse', date2String(formValues.endCurse));
        formCurseAdd.append('promoStart', date2String(formValues.promoStart));
        formCurseAdd.append('promoEnd', date2String(formValues.promoEnd));
        formCurseAdd.append('file', formValues.imgURL);

        if (activeCurse) {
            dispatch(startEventUpdating(formValues, formValues.id, formCurseAdd, formValues.units, formValues.proffesors));
        }
        else {
            dispatch(startEventAdding(formValues, formCurseAdd, formValues.units, formValues.proffesors));
        }

        setTitleValid(true);
        closeModal();
    }

    return (
        <Modal
            isOpen={modalOpen}
            closeTimeoutMS={200}
            onRequestClose={closeModal}
            style={customStyles}
            className="modal"
            overlayClassName="modal-fondo"
        >
            <h1> {activeCurse ? "Editar" : "Añadir"} curso</h1>
            <hr />
            <form
                className="container"
                onSubmit={handleSubmitForm}
            >

                <div className="form-group">
                    <label>Subir imagen de portada</label>
                    <input
                        type="file"
                        className={`form-control mt-4 ${!courseImageValid && 'is-invalid'}`}
                        onChange={handleCourseImage}
                        name="imgURL"
                    accept="image/*" 
                    />


                </div>
                <div className="form-group mt-5">
                    <label>Fecha de inicio</label>
                    <DateTimePicker
                        onChange={handleStartDateChange}
                        value={startCurse}
                        className="form-control"
                    />
                </div>

                <div className="form-group">
                    <label>Fecha de finalización</label>
                    <DateTimePicker
                        onChange={handleEndDateChange}
                        value={endCurse}
                        minDate={startCurse}
                        className="form-control"
                    />
                </div>


                <div className="form-group mt-5">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        rows="5"
                        name="promotion"
                        value={disponible}
                        onChange={handleDisponibleChange}
                        defaultChecked={disponible}
                    ></input>
                    <label className="form-check-label">¿El curso está disponible?</label>
                </div>


                <hr />
                <div className="form-group">
                    <label>Titulo:</label>
                    <input
                        type="text"
                        className={`form-control ${!titleValid && 'is-invalid'}`}
                        placeholder="Título del curso"
                        name="title"
                        autoComplete="off"
                        value={title}
                        onChange={handleInputChange}
                    />
                </div>



                <div className="form-group mt-1">
                    <label>Descripción:</label>
                    <textarea
                        type="text"
                        // className="form-control"
                        className={`form-control ${!descriptionValid && 'is-invalid'}`}
                        placeholder="Información general del curso"
                        rows="5"
                        name="subtitle"
                        value={subtitle}
                        onChange={handleInputChange}
                    ></textarea>
                </div>

                <hr />
                <div className="form-group mt-1">
                    <label>Precio:</label>
                    <input
                        type="number"
                        className={`form-control ${!priceValid && 'is-invalid'}`}
                        placeholder="Ejemplo: 30.00"
                        autoComplete="off"
                        rows="5"
                        name="price"
                        value={price}
                        onChange={handleInputChange}
                    ></input>
                </div>


                <hr />
                <div className="form-group mt-1">
                    <label>Duración:</label>
                    <input
                        type="text"
                        className={`form-control ${!durationValid && 'is-invalid'}`}
                        placeholder="Ejemplo: 40 horas, 15 en vivo por la plataforma Zoom y 25 autónomas."
                        rows="5"
                        name="duration"
                        value={duration}
                        onChange={handleInputChange}
                    ></input>
                </div>

                <div className="form-group mt-1">
                    <label>Horario:</label>
                    <input
                        type="text"
                        className={`form-control ${!horarioValid && 'is-invalid'}`}
                        placeholder="Ejemplo: Lunes a Viernes 19:00 a 22:00."
                        rows="5"
                        name="horario"
                        value={horario}
                        onChange={handleInputChange}
                    ></input>
                </div>

                <div className="form-group mt-1">
                    <label>Evaluación:</label>
                    <input
                        type="text"
                        className={`form-control ${!evaluacionValid && 'is-invalid'}`}
                        placeholder="Describa como será el método de evaluación"
                        rows="5"
                        name="evaluacion"
                        value={evaluacion}
                        onChange={handleInputChange}
                    ></input>
                </div>

                <div className="form-group mt-1">
                    <label>Certificación:</label>
                    <input
                        type="text"
                        className={`form-control ${!certificacionValid && 'is-invalid'}`}
                        placeholder="Describa como será el certificado"
                        rows="5"
                        name="certificacion"
                        value={certificacion}
                        onChange={handleInputChange}
                    ></input>
                </div>

                <hr />
                <div className="form-group mt-5">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        rows="5"
                        name="promotion"
                        value={promotion}
                        onChange={handleCheckPromoChange}
                        defaultChecked={promotion}
                    ></input>
                    <label className="form-check-label">¿Existe promoción?</label>
                </div>


                <div className={`form-group ${!promotion && 'admin__modal-promo-bool-false'}`}>
                    <label>Fecha de inicio de la promoción</label>
                    <DateTimePicker
                        onChange={handleStartDateChangePromo}
                        value={promoStart}
                        className="form-control"
                    />
                </div>
                <div className={`form-group ${!promotion && 'admin__modal-promo-bool-false'}`}>
                    <label>Fecha de fin de la promoción</label>
                    <DateTimePicker
                        onChange={handleEndDateChangePromo}
                        value={promoEnd}
                        minDate={promoStart}
                        className="form-control"
                    />
                </div>
                <div className={`form-group mt-1 ${!promotion && 'admin__modal-promo-bool-false'}`}>
                    <label>Precio para promoción:</label>
                    <input
                        type="number"
                        className="form-control"
                        placeholder="Ejemplo: 24.99"
                        rows="5"
                        name="pricePromotion"
                        value={pricePromotion}
                        onChange={handleInputChange}
                    ></input>
                </div>


                <hr />
                <div className="form-group">
                    <label>Nivel de dificultad</label>
                    <select
                        className="form-control"
                        type="text"
                        className="form-control"
                        rows="5"
                        name="difficult"
                        value={difficult}
                        onChange={handleInputChange}
                    >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>
                </div>


                <div className="form-group mt-5">
                    <label className="mb-1">PROFESORES</label><br />
                    {
                        proffesors?.length > 0 ?
                            proffesors?.map((proffesor, indexProf) => {
                                return (
                                    <ProfessorForm key={indexProf} setDeleProffesor={setDeleProffesor} proffesor={proffesor} indexProf={indexProf} formValues={formValues} setFormValues={setFormValues} />
                                )
                            })
                            :
                            <div className="text-muted ml-1">No existen profesores actualmente...</div>
                    }

                    <label>Subir imagen del profesor</label>
                    <input
                        className={`form-control ${!imageProfesorValid && 'is-invalid'}`}
                        type="file"
                        name="imgProf"
                        accept="image/*"
                        onChange={handleProfImageChange}
                    />

                    <label className="mt-2">Nombre del profesor:</label>
                    <input
                        type="text"
                        className={`form-control ${!profNameValid && 'is-invalid'}`}
                        placeholder="Nombre del profesor"
                        name="name"
                        autoComplete="off"
                        value={name}
                        onChange={handleInputChangeProffesor}
                    />
                    <label>País:</label>
                    <input
                        type="text"
                        className={`form-control ${!countryNameValid && 'is-invalid'}`}
                        placeholder="País de orígen"
                        name="country"
                        autoComplete="off"
                        value={country}
                        onChange={handleInputChangeProffesor}
                    />
                    <label>Descripción del profesor:</label>
                    <textarea
                        type="text"
                        className={`form-control ${!descriptionNameValid && 'is-invalid'}`}
                        placeholder="Información general del curso"
                        rows="5"
                        name="description"
                        value={description}
                        onChange={handleInputChangeProffesor}
                    ></textarea>

                    <button
                        className="btn btn-primary mt-2"
                        onClick={handleAddProffesorButton}
                    >
                        Añadir profesor
                    </button>
                </div>







                <div className="form-group mt-5">
                    <label className="mb-1">UNIDADES</label><br />
                    {
                        units?.length > 0 ?
                            units?.map((unit, indexUnit) => {
                                return (
                                    <UnitForm key={indexUnit} setDeleUnit={setDeleUnit} unit={unit} indexUnit={indexUnit} formValues={formValues} setFormValues={setFormValues} />
                                )
                            })
                            :
                            <div className="text-muted ml-1">No existen unidades actualmente...</div>
                    }
                    <label className="mt-2">Título de la unidad:</label>
                    <input
                        type="text"
                        className={`form-control ${!unitTitleValid && 'is-invalid'}`}
                        placeholder="Título de la unidad"
                        name="unitTitle"
                        autoComplete="off"
                        value={unitTitle}
                        onChange={handleInputChangeUnit}
                    />
                    <label>Descripción de la unidad:</label>
                    <textarea
                        type="text"
                        className={`form-control ${!unitContentValid && 'is-invalid'}`}
                        placeholder="Descripción de la unidad:"
                        rows="5"
                        name="unitContent"
                        value={unitContent}
                        onChange={handleInputChangeUnit}
                    ></textarea>

                    <button
                        className="btn btn-primary mt-2"
                        onClick={handleAddUnitButton}
                    >
                        Añadir Unidad
                    </button>
                </div>


                <button
                    type="submit"
                    className="btn btn-block btn-primary mt-5"
                >
                    <i className="far fa-save"></i>
                    <span> Guardar</span>
                </button>

            </form>
        </Modal>
    )
}
