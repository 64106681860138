import { combineReducers } from 'redux'
import { uiReducer } from './uiReducer'
import { internalCursesReducer } from "./internalCursesReducer";
import { authReducer } from './authReducer';


export const rootReducer = combineReducers({
    ui: uiReducer,
    internalCurses: internalCursesReducer,
    auth: authReducer
})