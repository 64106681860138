import React from 'react'
import { useDispatch } from 'react-redux';
import { uiOpenModal } from '../../actions/ui';
import { curseSetActive, startEventDeleting } from '../../actions/cursesInternal';

// import { ImageModal } from '../helpComponents/ImageModal';
import { Images } from '../helpComponents/Images'
import { Star } from '../helpComponents/Star';
import Swal from 'sweetalert2/dist/sweetalert2.js'

import moment from 'moment';
import 'moment/locale/es';
import { string2Date } from '../../helpers/strDate';
moment.locale('es');
const baseUrlImg = process.env.REACT_APP_API_URL_IMAGES+"/";

export const CursosAdminCard = ({ curse }) => {

    const dispatch = useDispatch()

    const handleEditarClick = (e) => {

        dispatch(curseSetActive(curse));
        dispatch(uiOpenModal());
    }

    const handleDeleteCurse = () => {
        Swal.fire({
            title: '¿Seguro que deseas eliminar el curso?',
            text: 'Este curso ya no estará disponible!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, borrar el curso!',
            cancelButtonText: 'No, volver'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(curseSetActive(curse));
                dispatch(startEventDeleting(curse.id));
                Swal.fire(
                    'Curso eliminado!',
                    'El curso se ha eliminado satisfactoriamente',
                    'success'
                )
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelado',
                    'Todo está como siempre',
                    'error'
                )
            }
        })
    }
    return (
        <div className="cursos__card flex flex-row max-container-width pointer">
            <div className="cursos__card-image-container flex flex-center">
                <Images imageURL={baseUrlImg+curse.imgURL} />
            </div>
            <div className="cursos__card-text-container">
                <div className="cursos__card-title">{curse.title}</div>
                <div className="cursos__card-subtitle">
                    {curse.subtitle}
                </div>
                <div className="cursos__card-text-small cursos__card-profesor">Impartido por:
                    {curse.proffesors.map(profesor => (
                        <span key={profesor.idProfesor}> {profesor.name}, </span>
                    ))}
                </div>
                <div className="cursos__card-text-small cursos__card-dificultad">
                    Dificultad:
                    {
                        <Star number={curse.difficult} />
                    }
                </div>
                <div className="cursos__card-text-small cursos__card-inicio">Inicio: {moment(string2Date(curse.startCurse), 'mm/dd/yyyy').format('LL')}</div>
                <div className="cursos__card-text-small cursos__card-fin">Fin: {moment(string2Date(curse.endCurse), 'mm/dd/yyyy').format('LL')}</div>
            </div>
            <div className="cursos__card-price-container">
                {curse.promotion && moment().isBetween(curse.promoStart, curse.promoEnd)?
                    <>
                        <div className="cursos__card-price">{curse.pricePromotion} US$</div>
                        <div className=" cursos__card-promo">{curse.price} US$</div>
                    </>
                    :
                    <>
                        <div className="cursos__card-price">{curse.price} US$</div>
                    </>
                }
                <div className="max-container-width flex flex-column">
                    <button
                        className="btn btn-primary"
                        onClick={handleEditarClick}
                    >
                        Editar
                    </button>
                    <button
                        className="btn btn-danger mt-1"
                        onClick={handleDeleteCurse}
                    >
                        Eliminar
                    </button>
                </div>
            </div>
        </div>
    )
}
