import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams, Redirect } from 'react-router-dom';

import Aos from 'aos';
import 'aos/dist/aos.css'

import moment from 'moment';
import 'moment/locale/es';
import { fetchFrontReq } from '../../helpers/fetch';
import { date2String } from '../../helpers/strDate';
import Swal from 'sweetalert2';
import { LoadingScreen } from './LoadingScreen';
moment.locale('es');

const data = {
    nombres: '',
    cedula: '',
    telefono: '',
    correo: '',
    ciudad: '',
    imgDeposit: '',
}

export const PagarScreen = ({ history }) => {

    useEffect(() => {
        Aos.init({ duration: 1300 });
        window.scrollTo(0, 0)
    }, [])

    const { curses } = useSelector(state => state.internalCurses);

    const { cursoId } = useParams();
    const curse = curses.find(curseChild => curseChild.id == cursoId);

    if (!curse) {
        history.replace("/");
    }

    const [formValues, setFormValues] = useState(data);

    const [submitting, setSubmitting] = useState(false)

    const [nombreValid, setNombreValid] = useState(true);
    const [cedulaValid, setCedulaValid] = useState(true);
    const [telefonoValid, setTelefonoValid] = useState(true);
    const [correoValid, setCorreoValid] = useState(true);
    const [ciudadValid, setCiudadValid] = useState(true);
    const [fileValid, setFileValid] = useState(true);


    const handleInputChange = ({ target }) => {

        setFormValues({
            ...formValues,
            [target.name]: target.value
        })
    }

    const handleFileChange = ({ target }) => {
        setFormValues({
            ...formValues,
            imgDeposit: target.files[0]
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { nombres, cedula, telefono, correo, ciudad, imgDeposit } = formValues;
        if (nombres.trim().length < 2) {
            return setNombreValid(false);
        }
        else {
            setNombreValid(true);
        }
        if (cedula.trim().length < 2) {
            return setCedulaValid(false);
        }
        else {
            setCedulaValid(true);
        }
        if (telefono.trim().length < 2) {
            return setTelefonoValid(false);
        }
        else {
            setTelefonoValid(true);
        }
        if (correo.trim().length < 2) {
            return setCorreoValid(false);
        }
        else {
            setCorreoValid(true);
        }
        if (ciudad.trim().length < 2) {
            return setCiudadValid(false);
        }
        else {
            setCiudadValid(true);
        }

        if (imgDeposit === "") {
            return setFileValid(false);
        }
        else {
            setFileValid(true);
        }
        let formBuyCurse = new FormData();

        formBuyCurse.append('nombre', nombres);
        formBuyCurse.append('cedula', cedula);
        formBuyCurse.append('telefono', telefono);
        formBuyCurse.append('correo', correo);
        formBuyCurse.append('ciudad', ciudad);
        formBuyCurse.append('idCurso', curse.id);
        formBuyCurse.append('precioAPagar', curse.promotion?curse.pricePromotion:curse.price);
        formBuyCurse.append('fechaDelPago', date2String(moment()));
        formBuyCurse.append('curso', curse.title);
        formBuyCurse.append('imgDeposit', imgDeposit);

        setSubmitting(true);

        const resp = await fetchFrontReq("comprarcurso", formBuyCurse, 'POST');
        const body = await resp.json();

        if (body.ok) {
            Swal.fire('¡Listo! Tu solicitud se está procesando, pronto nos contactaremos a tu correo electrónico.')
            history.replace('/')
        }
        else {
            Swal.fire('Hubo un problema al ejecutar esta solicitud. Por favor intentalo mas tarde.');
        }

    }

    if (curse) {

        if (!curse.disponible) {
            return <Redirect to="/inicio" />
        }

        if (moment().isAfter(curse.start)) {
            return <Redirect to="/inicio" />
        }

        const { nombres, cedula, telefono, correo, ciudad } = formValues;

        return (
            <div data-aos="fade" className="screen__new-screen pagar__main flex flex-center">
                <div className="pagar__containter flex flex-column flex-center">
                    <div className="pagar__title">¡Muchas gracias por su compra!</div>
                    <div className="pagar__texto">Por favor ingrese tus datos para poder continuar con el proceso</div>
                    <hr className="hr mt-3"></hr>
                    <div className="pagar__texto mt-4">Está comprando el curso:</div>
                    <div className="pagar__data mt-1">{curse.title}</div>
                    <div className="pagar__texto mt-4">Precio del curso:</div>
                    <div className="pagar__data mt-1">{curse.promotion?curse.pricePromotion:curse.price} US$</div>
                    <hr className="hr mt-3"></hr>
                    <div className="pagar__texto mt-4">Por favor proceda a realizar el pago, ya sea por transferencia bancaria o depósito</div>
                    <div className="pagar__texto mt-2">Cuenta de ahorros Banco Pichincha: 2203701489</div>
                    <div className="pagar__texto">Nombre: Jean Carlo Freire Valencia</div>
                    <div className="pagar__texto">Cédula: 1805102066</div>
                    <div className="pagar__texto">Correo: jeancarlo4561@gmail.com</div>
                    <hr className="hr mt-3"></hr>
                    <form className="max-container-width" onSubmit={handleSubmit}>
                        <div className="form-group mt-5">
                            <label>Nombres completos</label>
                            <input
                                type="text"
                                className={`form-control ${!nombreValid && 'is-invalid'}`}
                                placeholder="Ingresa tus nombres completos"
                                name="nombres"
                                value={nombres}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group mt-2">
                            <label >Cédula</label>
                            <input
                                type="text"
                                className={`form-control ${!cedulaValid && 'is-invalid'}`}
                                placeholder="Ingresa tu número de cédula"
                                name="cedula"
                                value={cedula}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group mt-2">
                            <label >Número telefónico</label>
                            <input
                                type="text"
                                className={`form-control ${!telefonoValid && 'is-invalid'}`}
                                placeholder="Ingresa tus número de contacto"
                                name="telefono"
                                value={telefono}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group mt-2">
                            <label >Correo</label>
                            <input
                                type="email"
                                className={`form-control ${!correoValid && 'is-invalid'}`}
                                placeholder="Ingresa tu correo electrónico"
                                name="correo"
                                value={correo}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group mt-2">
                            <label >Ciudad</label>
                            <input
                                type="text"
                                className={`form-control ${!ciudadValid && 'is-invalid'}`}
                                placeholder="Ingresa tu cuidad de residencia"
                                name="ciudad"
                                value={ciudad}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="form-group mt-4">
                            <label >Suba una captura o foto del depósito correspondiente</label>
                            <input
                                type="file"
                                className={`form-control ${!fileValid && 'is-invalid'}`}
                                id="uploadFile"
                                onChange={handleFileChange}
                            />

                        </div>
                        <button
                            type="submit"
                            className="btn btn-primary btn-block mt-4"
                        >
                            {submitting
                                &&
                                <span className="spinner-border spinner-border-sm " role="status" aria-hidden="true"></span>
                            }
                            <span className="ml-3">Pagar</span>
                        </button>
                    </form>


                </div>
            </div>
        )
    }
    else {
        return <LoadingScreen />
    }
}
