import React from 'react'

export const Star = ({number}) => {
    return (
        <>
        {
            [...Array(number)].map((val, index)=>(
                <i className="fas fa-star star-color" key={index}></i>
              ))
        }
        </>
    )
}
