import React from 'react'
import { Link } from 'react-router-dom';

import moment from 'moment';
import 'moment/locale/es';
import { string2Date } from '../../helpers/strDate';
moment.locale('es');

const baseUrlImg = process.env.REACT_APP_API_URL_IMAGES+"/";


export const DestacadosCard = ({ curse }) => {
    const { imgURL, title, subtitle, startCurse, disponible } = curse;
    return (
        <Link className="card pointer hover-increase"  style={{ width: "300px", margin: "20px", textDecoration: "none" }} to={`/curso/${curse.id}`}>
            <div className="home__card-dest-img-container">
            <img src={baseUrlImg+imgURL} className="card-img-top img-thumbnail" alt="..." />
            </div>
            <div className="card-body home__card-title flex flex-column">
                <h5 className="home__card-title">{title}</h5>
                <p className="home__card-subtitle">{subtitle}</p>
                {(moment().isSameOrBefore(string2Date(startCurse),'day') && disponible)?
                    <div className="text-muted home__text-muted-green">¡Próximo a iniciar!</div>
                    :
                    <div className="text-muted home__text-muted">No disponible</div>
                }
            </div>
        </Link>
    )
}
