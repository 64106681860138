import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startLogin } from '../../actions/auth';
import { useForm } from '../../hooks/useForm';

export const LoginScreen = ({history}) => {

    const dispatch = useDispatch();
    const { logged } = useSelector(state => state.auth)

    const [formValues, handleInputChange] = useForm({
        email: '',
        password: ''
    })

    const { email, password } = formValues

    const handleLogin = (e) => {
        e.preventDefault();
        dispatch( startLogin( email, password ) );
    }

    useEffect(() => {
        logged && history.replace('/admin');
    }, [logged])

    return (
        <>
            <div className="auth__main">
                <div className="auth__box-container">

                    <h3 className="auth__title">Panel de administración</h3>

                    <form onSubmit={handleLogin}>
                        <input
                            type="text"
                            placeholder="Ingresar e-mail"
                            name="email"
                            className="auth__input"
                            autoComplete="off"
                            value={email}
                            onChange={handleInputChange}

                        />
                        <input
                            type="password"
                            placeholder="Ingresar Contraseña"
                            name="password"
                            className="auth__input"
                            value={password}
                            onChange={handleInputChange}

                        />
                        <button
                            type="submit"
                            className="btn btn-primary btn-block"
                        >
                            Ingresar
                        </button>

                        <hr />

                    </form>
                </div>
            </div>
        </>
    )
}
