import React from 'react'
import { FooterS1 } from './FooterS1'
import { FooterS2 } from './FooterS2'
import { FooterS3 } from './FooterS3'


export const FooterV1 = () => {
    return (
        <div className="footer__v1">
            <FooterS1 />
            <FooterS2 />
            <FooterS3 />
        </div>
    )
}
