import React, { useEffect } from 'react';
import foco from './../images/home_foco.png';

import Aos from 'aos';
import 'aos/dist/aos.css'


export const Cartel2 = () => {

    useEffect(() => {
        Aos.init({duration: 2000})
    }, [])

    return (
        <div className="flex flex-row home__card2-main hover-increase-slow">
            <div className="flex flex-column home__card2-img-container">
                <img className="home__card2-img" src={foco} alt="card1" />
            </div>
            <div data-aos="fade-right" className="flex flex-center  flex-row home__card2-text-container">
                <ul className="home__card2-ul flex flex-column flex-center pointer">
                    <li className="home__card2-li hover-increase">Horarios flexibles para que puedas asistir y seguir llenandote de conocimiento</li>
                    <li className="home__card2-li hover-increase">Certificados de todos los talleres y cursos realizados</li>
                    <li className="home__card2-li hover-increase">Aula virtual en donde encontrarás toda la información ordenadamente</li>
                </ul>
                <ul className="home__card2-ul flex flex-column flex-center pointer">
                    <li className="home__card2-li hover-increase">Profesores con mucha experiencia en investigación y enseñanza</li>
                    <li className="home__card2-li hover-increase">Material adicional para que puedas complementar todo lo aprendido</li>
                </ul>
            </div>
        </div>
    )
}
