import React, { useEffect, useState } from 'react'
import AnimatedNumber from 'react-animated-number';

import Aos from 'aos';
import 'aos/dist/aos.css'


export const Cartel3 = () => {

    useEffect(() => {
        Aos.init({duration: 2000})
    }, [])


    const [counter1, setCounter1] = useState(0)
    const [counter2, setCounter2] = useState(0)
    const [counter3, setCounter3] = useState(0)

    const handleCounter = ()=>{
        setCounter1(200);
        setCounter2(50);
        setCounter3(100);
    }
    return (
        <div 
            className="hover-increase-slow flex flex-row flex-center home__card1-main "
            onMouseOver={handleCounter}
        >

            <div data-aos="fade-up" className="flex flex-center flex-column" style={{width:"300px", margin:"0px"}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" className="bi bi-people-fill" viewBox="0 0 16 16">
                    <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                    <path fillRule="evenodd" d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/>
                    <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
                </svg>
                <div className="card-body  home__card3-text-container mt-4">
                    <span className="home__card3-text">Cientos</span>
                </div>
                <div>
                    <p className="home__card3-text mt-3"> de estudiantes han confiado en nosotros</p>
                </div>
            
            </div>

            <div data-aos="fade-up" className="flex flex-center flex-column" style={{width:"300px", margin:"00px"}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" className="bi bi-patch-check-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z"/>
                </svg>
                <div className="card-body home__card3-text-container">
                    <span className="home__card3-text-decoration">+</span>
                    <AnimatedNumber 
                        value={counter2}
                        style={{
                            transition: '0.8s ease-out',
                            fontSize: 35,
                            fontWeight:500,
                            transitionProperty:
                                'background-color, color, opacity'
                        }}
                        duration={2000}
                        formatValue={n => n.toFixed(0)}
                    />
                </div>
                <div>
                    <p className="home__card3-text">horas de video en cursos impartidas</p>
                </div>
            </div>

            <div data-aos="fade-up" className="flex flex-center flex-column" style={{width:"300px", margin:"0px"}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" className="bi bi-bank2" viewBox="0 0 16 16">
                <path d="M8.277.084a.5.5 0 0 0-.554 0l-7.5 5A.5.5 0 0 0 .5 6h1.875v7H1.5a.5.5 0 0 0 0 1h13a.5.5 0 1 0 0-1h-.875V6H15.5a.5.5 0 0 0 .277-.916l-7.5-5zM12.375 6v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zM8 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM.5 15a.5.5 0 0 0 0 1h15a.5.5 0 1 0 0-1H.5z"/>
                </svg>
                <div className="card-body home__card3-text-container">
                    <AnimatedNumber 
                        value={counter3}
                        style={{
                            transition: '0.8s ease-out',
                            fontSize: 35,
                            fontWeight:500,
                            transitionProperty:
                                'background-color, color, opacity'
                            }}
                        duration={1000}
                        formatValue={n => n.toFixed(0)}
                    />
                    <span className="home__card3-text-decoration">%</span>
                </div>
                <div>
                    <p className="home__card3-text">de estudiantes satisfechos</p>
                </div>
            </div>



        </div>

    )
}
