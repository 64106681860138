import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom'
import { Footer } from '../components/footer/Footer';
import { NavBar } from "../components/navbar3/NavBar";
import { ConocenosScreen } from '../components/Screens/ConocenosScreen';
import { CursoScreen } from '../components/Screens/CursoScreen';
import { CursosScreen } from '../components/Screens/CursosScreen';
import { HomeScreen } from '../components/Screens/HomeScreen';
import { PagarScreen } from '../components/Screens/PagarScreen';

export const DashboardRouter = () => {


  return (
    <>
      <NavBar />

      <div className="screen__public-content flex flex-column">
        <Switch>
          <Route exact path="/inicio" component={HomeScreen} />
          <Route exact path="/conocenos" component={ConocenosScreen} />
          <Route exact path="/cursos" component={CursosScreen} />
          <Route exact path="/curso/:cursoId" component={CursoScreen} />
          <Route exact path="/pagar/:cursoId" component={PagarScreen} />
          <Redirect to="/inicio" />
        </Switch>
      </div>

      <Footer />
    </>
  )
}
