import React, { useEffect, useState } from 'react'

import Aos from 'aos';
import 'aos/dist/aos.css'
import { fetchFrontReq } from '../../helpers/fetch';
import Swal from 'sweetalert2';

export const ConocenosScreen = ({history}) => {

    const [camposMessage, setCamposMessage] = useState({
        nombreCampo: "",
        emailCampo: "",
        descripcionCampo: ""
    });


    const [submitting, setSubmitting] = useState(false)


    const { nombreCampo, emailCampo, descripcionCampo } = camposMessage;

    useEffect(() => {
        Aos.init({ duration: 1300 });
        window.scrollTo(0, 0)
    }, []);

    const handleInputChange = ({ target }) => {
        setCamposMessage({
            ...camposMessage,
            [target.name]: target.value
        })
    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        let formContactanos = new FormData();
        formContactanos.append('nombre', nombreCampo);
        formContactanos.append('email', emailCampo);
        formContactanos.append('mensaje', descripcionCampo);

        setSubmitting(true);

        const resp = await fetchFrontReq("contactanos", formContactanos, 'POST');
        const body = await resp.json();

        if(body.ok){
            Swal.fire('Tu mensaje se ha enviado! Te responderemos lo antes posible.');
            history.replace("/");
        }
        else{
            Swal.fire('Hubo un problema al enviar tu mensaje! Por favor intentalo mas tarde.');
        }
    }

    return (
        <div data-aos="fade" className="screen__new-screen flex flex-column max-container-width mb-5">
            <div className="conocenos__main flex flex-column max-container-width">

                <div className="conocenos__texts-main flex flex-column max-container-width flex-center">
                    <div className="max-container-width flex flex-column conocenos__title">
                        <h3 className="conocenos__title">¡Comprometidos a alcanzar tus metas!</h3>
                    </div>
                    <div className="conocenos__textos-container flex flex-row max-container-width flex-center">
                        <div className="conocenos__card-text conocenos__card-text3 flex flex-center-vertical flex-column">
                            <div className="max-container-width flex flex-center conocenos__card-number1">01</div>
                            <div className="conocenos__card-icon"><i className="far fa-hand-pointer conocenos__icon-texts"></i></div>
                            <div className="conocenos__card-title">Misión</div>
                            <div className="conocenos__card-text-content">Centro de Investigación Científica, se interesa por impartir temas de análisis a la colectividad con responsabilidad y compromiso de fomentar una cultura científica de excelencia y la correcta toma de decisiones basadas en datos y cursos de capacitación continua en temas estadísticos y metodologías de investigación, a través del conocimiento adquirido, para su mejor desenvolvimiento profesional.</div>
                        </div>
                        <div className="conocenos__card-text conocenos__card-text2 flex flex-center-vertical flex-column">
                            <div className="max-container-width flex flex-center conocenos__card-number2">02</div>
                            <div className="conocenos__card-icon"><i className="far fa-eye conocenos__icon-texts"></i></div>
                            <div className="conocenos__card-title">Visión</div>
                            <div className="conocenos__card-text-content">Ser un centro de investigación y capacitación continua líder en el país con soporte científico y tecnológico para aportar al desarrollo de destrezas, competencias y conocimientos que contribuyan al crecimiento personal y profesional mediante un servicio de calidad y con los mejores profesionales nacionales y extranjeros.</div>
                        </div>
                        <div className="conocenos__card-text conocenos__card-text1 flex flex-center-vertical flex-column">
                            <div className="max-container-width flex flex-center conocenos__card-number3">03</div>
                            <div className="conocenos__card-icon"><i className="far fa-question-circle conocenos__icon-texts"></i></div>
                            <div className="conocenos__card-title">¿Quiénes somos?</div>
                            <div className="conocenos__card-text-content">Estamos aquí con el fin de ayudar a que obtengas todo el conocimiento que necesites, con el fin de que en el futuro tengas grandes oprtunidades de trabajar o de dar trabajo. A travez de nuestros cursos dictados por personal altamente capacitado.</div>
                        </div>
                    </div>

                </div>


                <div className="max-container-width flex flex-row conocenos__contacto">
                    <div className="conocenos__col conocenos__col1 flex flex-column flex-center">
                        <div className="conocenos__subt1">Puedes realizar cualquier pregunta a nuestro número:</div>
                        <div className="conocenos__subt1">(+593) 095 964 8918</div>
                        <div className="conocenos__subt2 mt-3">También puedes contactarnos mediante:</div>
                        <div className="max-container-width flex flex-column flex-center mt-2">
                            <a target="_blank" rel="noopener noreferrer" className="btn btn-primary conocenos__btn conocenos__whats" href="https://wa.me/593959648918">
                                <span className="mr-1"><i className="fab fa-whatsapp conocenos__icon"></i></span>
                                Whatsapp
                            </a>
                            <a target="_blank" rel="noopener noreferrer" className="btn btn-primary conocenos__btn conocenos__face mt-2" href="https://www.facebook.com/SolucionesAcademicasRV/">
                                <span className="mr-1"><i className="fab fa-facebook-f conocenos__icon"></i></span>
                                Facebook
                            </a>


                            <p>
                                <button className="btn btn-primary conocenos__btn conocenos__email mt-2 mb-1" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                    <span className="mr-1"><i className="far fa-envelope conocenos__icon"></i></span>
                                    Email
                                </button>
                            </p>
                            <div className="collapse" id="collapseExample">
                                <div className="card card-body">
                                    solucionesacademicasrv@gmail.com
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="conocenos__col conocenos__col2 flex flex-column">

                        <form onSubmit={handleSubmit}>
                            <h2 className="text-center">Déjanos un mensaje</h2>
                            <div className="form-group">
                                <label className="mt-1">Nombre:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    aria-describedby="emailHelp"
                                    placeholder="Ingresa tu nombre y apellido"
                                    onChange={handleInputChange}
                                    name="nombreCampo"
                                    value={nombreCampo}
                                />
                            </div>
                            <div className="form-group">
                                <label className="mt-1">Email: </label>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Ingresa tu email"
                                    onChange={handleInputChange}
                                    name="emailCampo"
                                    value={emailCampo}
                                />
                            </div>
                            <div className="form-group">
                                <label className="mt-1">Mensaje: </label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    onChange={handleInputChange}
                                    name="descripcionCampo"
                                    value={descripcionCampo}
                                />

                            </div>
                            <button type="submit" className="btn btn-primary btn-block mt-3">
                            {submitting
                            &&
                            <span class="spinner-border spinner-border-sm " role="status" aria-hidden="true"></span>
                        }
                        <span className="ml-3">Enviar mensaje</span>
                                </button>
                        </form>
                    </div>


                </div>

                <div className="max-container-width flex flex-center">
                    <div className="card conocenos__card-directions mt-5">

                        <div className="card-body">
                            <h5 className="card-title">¡Visítanos!</h5>
                            <p className="card-text">Estamos ubicados en la Av Los Atis y Pasaje, Ambato 180206, Ecuador.</p>
                        </div>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8388597591916!2d-78.624904885899!3d-1.269589835964678!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91d383e5144591d5%3A0x49618b1c445dcaff!2sSoluciones%20Acad%C3%A9micas%20RV.!5e0!3m2!1sen!2sec!4v1628629628659!5m2!1sen!2sec"
                            width="100%"
                            height="420"
                            style={{ border: "0" }}
                            allowFullScreen=""
                            title="Soluciones Académicas RV"
                            loading="lazy">
                        </iframe>
                    </div>

                </div>
            </div>
        </div>
    )
}
