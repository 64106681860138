import React from 'react'
import { CursosActivos } from './CursosActivos'
import { CursosAnteriores } from './CursosAnteriores'

export const CursosContent = ({ filterShow }) => {
    return (
        <div className="flex flex-column cursos__content-main">
            {filterShow === 'All' ?
                <>
                    <CursosActivos />
                    <CursosAnteriores />
                </>
                : filterShow === 'Active' ?
                    <CursosActivos />
                    :
                    <CursosAnteriores />

            }
        </div>
    )
}
