import Swal from "sweetalert2";
import { fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";


export const startLogin = ( email, password ) => {
    return async( dispatch ) => {

        const resp = await fetchSinToken( 'login', { email, password }, 'POST' );
        const body = await resp.json();
        if( body.ok ) {
            dispatch( login({
                uid: body.uid,
                name: body.name
            }) );
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Hubo un error',
                text: body.msg,
              })
        }
        

    }
}


export const login = (credentials)=>({
    type: types.login,
    payload: credentials
});

export const logout = ()=>({
    type: types.logout,
});
