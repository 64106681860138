import React, { useState } from 'react'
import { NavLink, Redirect } from 'react-router-dom'
import { NavbarLogo } from './NavbarLogo'
import { NavCourSearcList } from './NavCourSearcList'
import { NavItemsList } from './NavItemsList'

export const NavBar = () => {

    const [showItems, setShowItems] = useState(false)
    const [showSearch, setShowSearch] = useState(false)

    const showItemsSmall = () => {
        setShowItems(!showItems);
        setTimeout(() => { setShowItems(showItems); }, 5000);
        if (showSearch) {
            setShowSearch(!showSearch);
        }

    }
    const showSearchCoursesSmall = () => {
        setShowSearch(!showSearch);
        setTimeout(() => { setShowSearch(showSearch); }, 5000);
        if (showItems) {
            setShowItems(!showItems);
        }
    }

    const handleSearch = () => {
        return <Redirect to="/cursos" />

    }
    return (
        <>
            <nav className="navbar__main">
                <div className="navbar__navbarCourseSearch">
                    <div className="navbar__navItem">
                        <NavLink className="navbar__nav-link navbar__navtext mr-1" active="navbar__navtext-active" to="/cursos">
                            <span><i className="fas fa-file-alt navbar__icon1 mr-1"></i></span>
                            Cursos
                        </NavLink>
                    </div>
                    <form onSubmit={handleSearch}>
                        
                    <div className="navbar__navItem">
                        <input
                            className="navbar__navSearch"
                            placeholder="Buscar"
                            />
                    </div>
                            </form>
                    <button
                        className="navbar__coursesSearchButton navbar__buttonSmall"
                        onClick={showSearchCoursesSmall}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                    </button>
                </div>

                <NavbarLogo />

                <div className="navbar__navbarItems">
                    <div className="navbar__navItem">
                        <NavLink className="navbar__nav-link navbar__navtext ms-2" active="navbar__navtext-active" to="/inicio">Inicio</NavLink>
                    </div>
                    <div className="navbar__navItem">
                        <NavLink className="navbar__nav-link navbar__navtext ms-2" active="navbar__navtext-active" to="/conocenos">Quienes somos</NavLink>
                    </div>
                    <div className="navbar__navItem">
                        <a href="https://solucionesacademicasrv.com/moodle/login/index.php" className="btn btn-primary navbar__navtext navbar__navAulaVirtual">Aula Virtual</a>
                        {/* <a className="nav-link navtext NavAulaVirtual" href="#">Aula Virtual</a> */}
                    </div>
                    <button
                        className="navbar__itemsButton navbar__buttonSmall"
                        onClick={showItemsSmall}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                        </svg>
                    </button>
                </div>

            </nav>
            {showItems && <NavItemsList />}
            {showSearch && <NavCourSearcList />}
        </>
    )
}