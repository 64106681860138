import React from 'react'
import { NavLink } from 'react-router-dom'

export const FooterS2 = () => {
    return (
        <div className="footer__s2 flex flex-center">
            <div>
                <h5>Enlaces rápidos</h5>
                <hr className="hr"/>
                <p>
                    <NavLink className="footer__link" active="footer__link-active" to="/inicio">
                        Inicio
                    </NavLink>
                </p>
                <p>
                    <NavLink className="footer__link" active="footer__link-active" to="/cursos">
                        Cursos
                    </NavLink>
                </p>
                <p>
                    <NavLink className="footer__link" active="footer__link-active" to="/conocenos">
                        Conócenos
                    </NavLink>
                </p>
            </div>
        </div>
    )
}
