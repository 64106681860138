import React from 'react'
import { AddButton } from '../admin/AddButton'
import { CursosAdminContainer } from '../admin/CursosAdminContainer'
import { CursosModal } from '../admin/CursosModal'
import { NavbarAdmin } from '../admin/NavbarAdmin'

export const AdminScreen = () => {

    return (
        <div className="screen__new-screen">
            <NavbarAdmin />
            <AddButton />
            <CursosAdminContainer />
            <CursosModal />
        </div>
    )
}
