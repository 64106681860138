import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CursoCabecera } from '../curso/CursoCabecera'
import { CursoCuerpo } from '../curso/CursoCuerpo'
import { CursoProfessors } from '../curso/CursoProfessors'

import Aos from 'aos';
import 'aos/dist/aos.css'
import { LoadingScreen } from './LoadingScreen'

export const CursoScreen = ({history}) => {

    useEffect(() => {
        Aos.init({duration: 1300});
        window.scrollTo(0, 0)
    }, [])

    const {curses} = useSelector(state => state.internalCurses)
    const {cursoId} = useParams();


    const curse = curses.find(curse=> curse.id == cursoId);
    if(!curse){
        history.replace("/");
    } 

    return (
        curse?
        <div data-aos="fade" className="curso__main screen__new-screen flex flex-column">
            <div className="curso__direction max-container-width text-muted mt-4">Cursos &gt; {curse.title}</div>
            <CursoCabecera curse={curse}/>
            
            <div className="max-container-width flex flex-center">
                <hr className="max-container-width flex flex-center curso__hr"/>
            </div>
            <CursoCuerpo curse={curse} />
            <CursoProfessors curse={curse} />
        </div>
        :
        <LoadingScreen />
    )
}
