import React, { useEffect } from 'react';
import { Carrusel } from '../inicio/Carrusel';
import { Cartel1 } from '../inicio/Cartel1';
import { Cartel2 } from '../inicio/Cartel2';
import { Cartel3 } from '../inicio/Cartel3';
import { Cartel4 } from '../inicio/Cartel4';
import { Destacados } from '../inicio/Destacados';

import Aos from 'aos';
import 'aos/dist/aos.css'


export const HomeScreen = () => {
    useEffect(() => {
        Aos.init({duration: 1300});
        window.scrollTo(0, 0)
    }, [])
    return (
        <div data-aos="fade" className="screen__new-screen">
            <Carrusel />
            <Destacados />
            <Cartel1 />
            <Cartel2 />
            <Cartel3 />
            <Cartel4 />
        </div>
    )
}
