import React, { useEffect } from 'react'
import { Images } from '../helpComponents/Images'
import Logo1 from "./../images/SanPablo.png";
import Logo2 from "./../images/SanGabriel.png";
import Logo3 from "./../images/Apuc.png";
import Logo4 from "./../images/min_trabajo.png";
import Aos from 'aos';
import 'aos/dist/aos.css'


export const Cartel4 = () => {
    useEffect(() => {
        Aos.init({duration: 2000})
    }, [])

    return (
        <div className="home__cartel4-main max-container flex flex-column flex-center">
            <div className="flex flex-center title2 mt-4">Contamos con el respaldo de:</div>
            <div data-aos="fade-up" className="flex flex-row flex-center home__container-intituciones">
                <div className="home__cartel4-image-container_large hover-increase">
                    <img src={Logo4} alt="intitutions" className="home__cartel4-image" />
                </div>
                <div className="home__cartel4-image-container hover-increase">
                    <img src={Logo1} alt="intitutions" className="home__cartel4-image" />
                </div>
                <div className="home__cartel4-image-container hover-increase">
                    <img src={Logo2} alt="intitutions" className="home__cartel4-image" />
                </div>
                <div className="home__cartel4-image-container hover-increase">
                    <img src={Logo3} alt="intitutions" className="home__cartel4-image" />
                </div>
            </div>


        </div>
    )
}
